@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
.loading {
  display: flex;
  justify-content: center;
  margin: auto; }

.override-tabs-bp3 .bp3-tabs {
  color: #ffffff; }
  .override-tabs-bp3 .bp3-tabs .bp3-tab {
    text-align: center;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    width: 50%;
    margin: 0;
    height: 35px;
    padding-top: 0;
    align-items: baseline; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab-list {
      background-color: #30404d;
      justify-content: space-around; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab-panel {
      margin-top: 0; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab:hover {
      color: #ffffff;
      -webkit-transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
      transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .override-tabs-bp3 .bp3-tabs .bp3-tab-indicator-wrapper {
      width: 50% !important;
      height: 35px !important;
      z-index: 2; }
      .override-tabs-bp3 .bp3-tabs .bp3-tab-indicator-wrapper .bp3-tab-indicator {
        background-color: #ffffff;
        width: 50%;
        margin-left: 25%; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab[aria-selected="true"] {
      color: #ffffff;
      -webkit-transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
      transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.navBar {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 1 1;
  padding: 10px 0; }
  .navBar .bp3-navbar-heading .logo {
    width: 80px; }
  .navBar .header-button-label {
    display: none; }
  .navBar__group {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 2px 1px 1px 0 rgba(0, 0, 0, 0.5);
    flex-direction: column;
    height: auto; }
    .navBar__group ul {
      list-style: none;
      text-align: center;
      width: 100%;
      margin: 0;
      padding: 0; }
    .navBar__group li {
      width: 100%;
      position: relative; }
    .navBar__group .header-link {
      width: 100%;
      margin: 10px; }
      .navBar__group .header-link button {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 0.5em 1em;
        z-index: 1; }
      .navBar__group .header-link.active::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #293742; }
      .navBar__group .header-link.active::after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 5px);
        background-image: url("/images/nav__arrow-right.svg");
        background-repeat: no-repeat;
        display: inline-block;
        width: 15px;
        height: 20px;
        vertical-align: middle; }

.override-tabs-bp3 .bp3-tabs {
  color: #ffffff; }
  .override-tabs-bp3 .bp3-tabs .bp3-tab {
    text-align: center;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    width: 50%;
    margin: 0;
    height: 35px;
    padding-top: 0;
    align-items: baseline; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab-list {
      background-color: #30404d;
      justify-content: space-around; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab-panel {
      margin-top: 0; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab:hover {
      color: #ffffff;
      -webkit-transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
      transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .override-tabs-bp3 .bp3-tabs .bp3-tab-indicator-wrapper {
      width: 50% !important;
      height: 35px !important;
      z-index: 2; }
      .override-tabs-bp3 .bp3-tabs .bp3-tab-indicator-wrapper .bp3-tab-indicator {
        background-color: #ffffff;
        width: 50%;
        margin-left: 25%; }
    .override-tabs-bp3 .bp3-tabs .bp3-tab[aria-selected="true"] {
      color: #ffffff;
      -webkit-transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
      transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }

body {
  font-family: "Roboto", sans-serif; }

.main {
  display: flex; }
  .main__header {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 70px; }
    .main__header .bp3-navbar-heading .logo {
      width: 50px; }
  .main__content {
    display: flex;
    flex: auto;
    overflow: auto;
    height: 100vh; }
    .main__content > div {
      width: 100%; }

